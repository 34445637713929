@font-face {
  font-family: Apercu;
  src: url('assets/apercu_medium-webfont.woff');
}

@font-face {
  font-family: Apercu;
  src: url('assets/apercu_regular-webfont.woff');
  font-weight: 200;
}

@font-face {
  font-family: Caecilia;
  src: url('assets/Caecilia-Heavy.woff');
}

@font-face {
  font-family: 'icomoon';
  src:url('assets/icomoon/icomoon.eot');
  src:url('assets/icomoon/icomoon.eot?#iefix') format('embedded-opentype'),
  url('assets/icomoon/icomoon.woff') format('woff'),
  url('assets/icomoon/icomoon.ttf') format('truetype'),
  url('assets/icomoon/icomoon.svg#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.small-title {
  font-family: Caecilia, sans-serif;
  color: #004F71;
  text-transform: uppercase;
  font-size: 10px;
  letter-spacing: 1px;
}

a {
  text-decoration: none;
}
.ReactModal__Overlay, .ReactModal__Overlay--after-open {
  z-index: 100;

  background-color: rgba(90, 90, 90, 0.75) !important;
}